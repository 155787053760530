body {
    background-color: rgb(255, 255,255);
  }
  
  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .image {
    width: 370px;
    margin-top: 50px;
  }
  
  @media only screen and (max-width: 770px) {
    .container {
      margin: 0 10%;
    }
  
    .image {
      width: 100%;
    }
  }